<template>
  <div class="dashboard" >
   <!--
    <h1 class="subheading grey--text ml-4">Dashboard</h1>


    <v-snackbar v-model="snackbar" top color="warning" flat>
          <span>Are you sure?</span>
          <v-btn flat color="white" @click="snackbar = false; deleteProject()">Yes</v-btn>
          <v-btn flat color="white" @click="snackbar = false; ">No</v-btn>
    </v-snackbar>
-->
    <v-container class="my-2">

    

<v-card flat dark>
     <!--  <v-card-title>
        <h2>Add a New Project</h2>
      </v-card-title>
-->
      <v-card-text>
       
<!--
          <v-alert type="error" :value="alerta">
            Type some title
          </v-alert>

         <v-textarea clearable rows="1"
          row-height="15" counter color="grey"
          outlined no-resize label="Title..." v-model="title"></v-textarea>
-->
        <v-textarea clearable rows="1"
          row-height="15"  counter color="grey"
          outlined  auto-grow label="Text..." v-model="description"></v-textarea>

<!--
    <v-textarea clearable  rows="1"
          row-height="15" color="grey"
          outlined  auto-grow label="URL..." v-model="shareURL"></v-textarea>
-->

<!-- TODO via filterProjects in dropdown packen ish 
 <v-textarea clearable  rows="1"
          row-height="15" color="grey"
          outlined  auto-grow label="Tags..." v-model="shareTags"></v-textarea>
--> 

<v-file-input
v-model="filesAttached"

 accept="video/*,image/*"
:prepend-icon="null"
show-size
outlined
      chips
      multiple
      label="Image or video..."
    ></v-file-input>



[second tweet with link from share target]
   <!-- 
    <v-text-field  label="Description" v-model="description"></v-text-field>
    -->     

           <!-- :rules="dateRules"  
         
          <v-menu>
          
            <v-text-field readonly :value="formattedDate" slot="activator" label="Due date" prepend-icon="date_range"></v-text-field>
            <v-date-picker v-model="due"></v-date-picker>
          </v-menu>
-->



<!-- 
  SCHEDULE
<v-dialog
        ref="dialogDate"
        v-model="datePickerModal"
        :return-value.sync="due"
        persistent
        width="290px"
      >
       OLD: < xx template v-slot:activator="{ on, attrs }" xxx>

          <template v-slot:activator="{ on }">
          <v-text-field
            v-model="due"
            label="Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        
        <v-date-picker
          v-model="due"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="datePickerModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialogDate.save(due)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-dialog
        ref="dialogTime"
        v-model="timePickerModal"
        :return-value.sync="time"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            label="Time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>

        <v-time-picker
          v-if="timePickerModal"
          v-model="time"
          full-width
            format="24hr"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="timePickerModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialogTime.save(time)"
          >
            OK
          </v-btn>
        </v-time-picker>
      </v-dialog>
-->



    
      </v-card-text>

     <v-card-text >
 <v-row
      class="pb-2 ma-0"
      justify="space-between"
    >
      <v-btn-toggle
        v-model="formatting"
        multiple
      >
        <v-btn @click="removeHashTags()">
         Remove # Line
        </v-btn>



        <v-btn @click="removeAtTags()">
        Remove @
        </v-btn>
    <v-btn @click="removeHashTagsLeading()">
         Remove #
        </v-btn>
        <v-btn  @click.native="description += ' Link below ⬇️'" class="text-lowercase">
        Link below ⬇️
        </v-btn>

        <v-btn @click="pasteFromClipboard()">
       
         <v-icon left>mdi-content-paste</v-icon>  PASTE

         
         
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle v-model="alignment">
        <v-btn  @click.native="description += ' https://'" class="text-lowercase">
        Add https://
        </v-btn>

 <v-btn  @click.native="description += '__'" class="text-lowercase">
       Add __
        </v-btn>

        <v-btn>
        <v-icon>mdi-format-align-left</v-icon>  CustomPaste
        </v-btn>

        <v-btn>
       <v-icon>mdi-format-align-right</v-icon>   CustomPaste2
        </v-btn>
      </v-btn-toggle>
    </v-row>

      <v-card-actions class="mt-10">
                  <v-btn  text large roundeded outlined  class="grey mx-0 mr-3" @click="submitForm()" >Add for later</v-btn>
    

   <v-btn large text class=" orange darken-2 mr-4" @click="shareViaWebShare(title,description,shareURL)" >Share Now</v-btn>
 
    <v-spacer></v-spacer>
<v-btn large text  @click="copyURL(description)" class="grey darken-1 mr-4"><v-icon >mdi-content-copy</v-icon> Text</v-btn>

      </v-card-actions>
 
</v-card-text>


    </v-card>

  <!-- TODO func aus tostart nehmen eigene func nehmen 
       

<v-card class="mt-10" flat dark>

  <v-layout row class="mb-3" wrap>

 
        <v-btn icon @click="filterProjects('ongoing'); todos = true">
          <v-icon color="warning">work</v-icon>
        </v-btn>
        
        <v-btn icon @click="filterProjects('overdue'); todos = true">
          <v-icon color="error">watch_later</v-icon>
        </v-btn>


        <v-btn icon @click="filterProjects('complete'); todos = true">
          <v-icon color="green">check_circle</v-icon>
        </v-btn>

              <v-btn icon @click="filterProjects('ongoing'); todos = true">
          <v-icon color="green">dashboard</v-icon>
        </v-btn>

      </v-layout>

      </v-card>
-->


<!--
      <v-layout row justify-center v-if="this.projects.length == 0">
        <v-layout column align-center>
          <span :class="{'grey--text display-3': $vuetify.breakpoint.smAndUp, 'display-1 grey--text': $vuetify.breakpoint.xsOnly}">Nothing here</span>
          <v-btn text color="primary" class="display-1" @click="openDialog">Add new Project</v-btn>
        </v-layout>
      </v-layout>

      // LENGTH COPY {{ this.projectsCopy.length }}

NOTES {{ this.projects.length }} 

      -->

     <draggable  class="mt-2" v-model="projects" @change="saveOrder" handle=".handle" :disabled="!todos" v-if="this.projects.length > 0">

       <v-card
            color="#grey"
            dark

   flat 
   class="mb-4" 
   v-for="(project, index) in projects" :key="index"
          >

     
       

            <v-card-title class="text-h5">
            {{ project.title }}
            </v-card-title>

            <v-card-subtitle> <pre class="text-subtitle-2">{{ project.content }}</pre></v-card-subtitle>

            <v-card-actions>
<v-btn large text outlined @click="shareViaWebShare(project.title,project.content,project.website)" class="pa-0 ma-0"><v-icon>mdi-share-variant</v-icon></v-btn>

<v-btn large text outlined @click="copyURL(project.content)" class="pa-0 ma-0 ml-4"><v-icon>mdi-content-copy</v-icon></v-btn>
              <v-spacer></v-spacer>

 <v-icon color="grey" size="30" class="handle" v-if="todos">drag_indicator</v-icon>

              <v-btn text outlined :class="`${project.status}`" @click="editMode = !editMode; indexToEdit = index; newTitle = project.title;" class="pa-0 ma-0 ml-3"><v-icon>edit</v-icon></v-btn>

           <v-btn icon color="grey" @click="deleteProject(index); indexToEdit = index;" class="pa-0 ma-0 ml-3"><v-icon>delete</v-icon></v-btn>
            </v-card-actions>
          
          </v-card>
  </draggable>




         
          <!--  <div class="caption grey--text ml-3" v-if="todos">Project Title</div> 
            <div class="caption grey--text" v-if="!todos">Project Title</div> -->


            <!-- TODO v-click-outside="if (editMode===true) { updateTitle(index) }" 
            
             v-click-outside="updateTitleAfterDB('index')"
             -->
      
           <!--  
          <div  v-on:dblclick="editMode = !editMode; indexToEdit = index; newTitle = project.title;" :class="{'mt-0 mb-1': $vuetify.breakpoint.smAndDown}" v-if="!editMode || index != indexToEdit">
            
         
                 
            </div>
 -->
<!-- 

@submit.prevent="updateTitle(index)""
 -->
            <v-form>
            <v-textarea rows="1"
                  auto-grow autofocus :color="`${project.status}s`" v-model="newTitle" v-if="editMode && indexToEdit == index" class="ma-0 pa-0"></v-textarea>
            </v-form>

         
      



<!--
             <v-btn @click="changeStatus(index)"   text small outlined rounded :class="`${project.status}  white--text px-0 mx-0`">
              {{ project.status }} 
              </v-btn>
              -->
          
  
  
      
    </v-container>


  </div>
</template>

<script>
  import db from '@/fb'
  import firebase from 'firebase'
  import draggable from 'vuedraggable';
  import { EventBus } from '@/event-bus.js';
import format from 'date-fns/format'
// import { LocalNotifications } from '@capacitor/local-notifications'


  export default {

    components: {
      draggable
    },
    
    // #r
    data() {
      return {
         alignment: 1,
      formatting: [],
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
        shareTags: [],
        filesAttached: [],
  shareTitle:"",
   shareText:"",
  shareURL: "",
        e7: null,
         time: null,
             timePickerModal: false,
         due: 0,
      menu: false,
      datePickerModal: false,
      menu2: false,
        xtime: null,
        title: '',
      description: '',
      descriptionX: 'xx',
  
            dateRules: [
        v =>(v.length >= 4) || 'Invalid format. Click to pick'
      ],
       loading: false,
        projects: [],
        projectsCopy: [],
        todos: true,
        userId: '',
        btnColor: '',
        snackbar: false,
        editMode: false,
        newTitle: "",
        indexToEdit: 0,
        drag: true
      }
    },

    mounted() {
  
 // Initialization on app startup, I do it in main.ts where the app is bootstraped/mounted

// console.log(navigator.share);

/*
LocalNotifications.createChannel({
  // To create channels (Android only), you do the following. 
    id: 'reminders',
    name: 'Reminders',
    description: 'Reminders you set within App',
    importance: 4
})
*/

// To register a tap of the notification and do something within your app

// I remove and re-register every time the app starts as I was having issues of double events being fired
/*
LocalNotifications.removeAllListeners()

LocalNotifications.addListener('localNotificationActionPerformed', (notification: ActionPerformed) => {
    if (notification.actionId === 'tap') {
        // Run some code in your app
    }
})
*/

       EventBus.$on('project-added', () => {
        this.saveOrder();
        
      });
      
     // this.filterProjects('ongoing')
  
 
    },

computed: {
    formattedDate() {
      return this.due ? format(this.due, 'Do MMM YYYY') : ''
    },
          
},
// #m
    methods: {
      async pasteFromClipboard() {

 try {
    const text = await navigator.clipboard.readText();
    console.log('Pasted content: ', text);
this.description = this.description += " " + text

  } catch (err) {
    console.error('Failed to read clipboard contents: ', err);
  }


      },
         removeHashTagsLeading() {
       this.description =  this.description.split('#').join('')
      },
      removeHashTags() {
       this.description =  this.description.replace(/^(\s*#\w+\s*)+$/gm, "")
      },
          removeAtTags() {
      this.description =  this.description.split('@').join('')
      },
      async copyURL(mytext) {
    try {
      await navigator.clipboard.writeText(mytext);
     // alert('Copied');
    } catch($e) {
      alert('Cannot copy');
    }
  },

  // ERSTMAL RAUS:    url: c, title: a,
      shareViaWebShare(a,b,c) {
     //   const hashtags 

     //    if("\n\n"+this.shareTags)
      //   hashtags = "\n\n"+this.shareTags
      // }

  navigator.share({
    files: this.filesAttached,
    text: b+this.shareTags
  })
},
/*
      async scheduleMessage() {
await LocalNotifications.schedule({
    notifications: [
        {
            id: new Date().getTime(),
            channelId: 'reminders', 
            title: 'Hey',
            body: '1830works',
            schedule: {
                on: {
                    hour: 18,
                    minute: 30
                },
                allowWhileIdle: true,
            },
            extra: {
             
            },
        },
    ],
})
      },
      */
      updateTitleAfterDB() {
// console.log(val);
      },
 submitForm() {
// && 
// this.due.length >= 4
// console.log("submitForm ")
// console.log("xxxxxxxxxx");
     // if(this.title.length > 0 ) {
      //   console.log("submit");
        this.loading = true;

       // ERSTMAL OFF this.scheduleMessage()

        const collectionRef = db.collection('users/'+this.userId+'/projects');
        
        //ongoing = tostart?! 
        const project = {
          title: this.title,
          content: this.description,
          website: this.shareURL,
          due: this.formattedDate, // "1111111", // ISSUE: TODO: this.format(this.due, 'Do MMM YYYY'),
          status: 'ongoing',
          priority: 100,
        }

        collectionRef.add(project).then(() => {
          this.loading = false;
          this.dialog = false;
          this.formReset();
        EventBus.$emit('project-added');
        });

  
        // this.alerta = false;
        
    //  } else {
       //  this.alerta = true;
    //  }
  // this.filterProjects('ongoing')
    },

    formReset() {
      this.title = '';
      this.description = '';
      this.due = null;
    },
      openDialog() {
        EventBus.$emit('dialog', true)
      },
    
      filterProjects(status){
    //  
 //  this.projects = this.projects
 // WARUM?    this.projects = this.projectsCopy
// this.saveOrder();

// console.log( this.projects);
        if (status === "complete") {
          this.projects = this.projects.filter(function(item) {
            return item.status === "complete";
          });

// todo ongoing zu focus nennen priority
        } else if (status === "ongoing") {
          this.projects = this.projects.filter(function(item) {
            return item.status === "ongoing";
          });

        } else if (status === "overdue") {
          this.projects = this.projects.filter(function(item) {
            return item.status === "overdue";
          });
        }  
// NEW this.projects.length
          
      },

      changeStatus(index) {
      
        const currentProject = this.projects[index]
        var docRef = db.collection("users/"+this.userId+"/projects").doc(currentProject.id);

        // LOGIC FOR STATUS UPDATE
     // var newStatus = 'complete'
     //     var btnColor = 'success'
      
        switch (currentProject.status) {
          case 'ongoing':
            var newStatus = 'complete'
            var btnColor = 'success'
            break;

          case 'complete':
            newStatus = 'overdue'
            btnColor = 'error'
            break;
          
          case 'overdue':
            newStatus = 'ongoing'
            btnColor = 'info'
            break;

          default:
            break;
        }


        // UPDATE DATABASE
        docRef.set({
            status: newStatus,

        }, { merge: true });

        // UPDATE LOCAL DATA
        currentProject.status = newStatus
        this.btnColor = btnColor

      },

      deleteProject(val) {
       // console.log("val", val);
        var indexVal = val // this.indexToEdit;

        const currentProject = this.projects[indexVal]
       // console.log("indexVal: ", indexVal);
        var docRef = db.collection("users/"+this.userId+"/projects").doc(currentProject.id);

        // DELETE ON DATABASE
        docRef.delete().then(function() {
          //console.log("Document successfully deleted!");
        }).catch(function(error) {
          alert(error.message);
        });


        // DELETE ON LOCAL DATA - beu index 0 ganz oben löscht der mehr als nur 0
        if (indexVal > -1) {
          this.projects.splice(indexVal, 1);
      //    this.projectsCopy.splice(indexVal, 1);
        }

        // UPDATE ORDER ON DATABASE
        this.saveOrder();

      },

      saveOrder() {

        for (let index = 0; index < this.projects.length; index++) {
          const currentProject = this.projects[index];
          var docRef = db.collection("users/"+this.userId+"/projects").doc(currentProject.id);

          // UPDATE PRIORITY ON DATABASE
          docRef.set({
            priority: index,

          }, { merge: true });
        
        }
           // ON MOUNTED/LOAD FILTER
       
      },

      updateTitle(index) {
        const currentProject = this.projects[index]
        var docRef = db.collection("users/"+this.userId+"/projects").doc(currentProject.id);

        docRef.set({
              title: this.newTitle,

          }, { merge: true });

          // UPDATE LOCAL DATA
          currentProject.title = this.newTitle

        this.editMode = false;
      },

    },

    created() {
      const user = firebase.auth().currentUser;
      if (user) {
        var userId = user.uid
        this.userId = userId
      } else {
        // not logged
      }

      var projectsRef = db.collection('users/'+userId+'/projects')

      // ORDER DATA BY PRIORITY
      projectsRef = projectsRef.orderBy("priority", "desc")

      // GET DATA
      projectsRef.onSnapshot(res => {
  
      const changes = res.docChanges();

      changes.forEach(change => {
        if (change.type === 'added') {
          this.projects.unshift({ // UNSHIF FOR ADD AT THE BEGINNING
            ...change.doc.data(),
            id: change.doc.id
            })
          }
        })
        // ON MOUNTED/LOAD FILTER
         this.filterProjects('ongoing')
      })
      

// WARUM?      this.projectsCopy = this.projects
    // setTimeout(function() {
     
 // }, 3000);

    }
    
  }

</script>

<style>

  .project.complete {
    border-left: 4px solid #3cd1c2;
  }

  .project.ongoing {
    border-left: 4px solid #ffaa2c;
  }

  .project.overdue {
    border-left: 4px solid #f83e70;
  }

  .theme--light.v-btn:not(.v-btn--icon).complete {
    backgrounded-color: #3cd1c2;
  }

  .theme--light.v-btn:not(.v-btn--icon).ongoing {
    backgrounded-color: #ffaa2c;
  }

  .theme--light.v-btn:not(.v-btn--icon).overdue {
    backgrounded-color: #f83e70;
  }

  .theme--light.v-btn.complete {
    color: #3cd1c2;
  }

  .theme--light.v-btn.ongoing {
    color: #ffaa2c;
  }

  .theme--light.v-btn.overdue {
    color: #f83e70;
  }

  .handle {
    cursor: move;
  }

</style>
