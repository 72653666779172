<template>

  <nav dark>

    <v-snackbar dark v-model="snackbar" :timeout="4000" top color="success">
      <span>Wow! You added a new project.</span>
      <v-btn text color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>

   <v-app-bar dark app flat>

      <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-bold">Share</span>
        <span class="font-weight-thin">To</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      

      <v-btn text color="grey" @click="signout">
      <!--  <span>Sign out</span> -->
        <v-icon right>exit_to_app</v-icon>
      </v-btn>

   </v-app-bar> 


    <!-- Drawer -->
    <v-navigation-drawer dark v-model="drawer" app class="grey darken-4">

      <v-layout column align-center>
        <v-flex class="mt-5 text-xs-center">
          <v-avatar size="130">
          <!--  <img :src="photoURL"> -->
          </v-avatar>
          <p class="white--text subheading mt-1 text-xs-center">
            {{name}}
          </p>
        </v-flex>
        <v-flex class="mt-3 mb-3">
          <Popup @projectAdded="snackbar=true" />
        </v-flex>
      </v-layout>

      <v-list>
        <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-item-action>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
      
    </v-navigation-drawer>


  </nav>

</template>


<script>
import Popup from './Popup'
import firebase from 'firebase'

export default {
  components: { Popup },
  data() {
    return {
      name: '',
      photoURL: '',
      drawer: false,
      links: [
        { icon: 'dashboard', text: 'Dashboard', route: '/dashboard'},
        { icon: 'settings', text: 'Settings', route: '/settings'},
      ],  
      snackbar: false,
    }
  },
  methods: {

    signout() {
      firebase.auth().signOut().then(() => {
        this.$router.replace('/auth')
      })
    }

  },

  created() {
    var user = firebase.auth().currentUser;

    if (user) {
      this.name = user.displayName;
      
      if(user.photoURL){
        this.photoURL = user.photoURL;
      } else {
      //  this.photoURL = "logo.svg"
      }
    } else {
      //
    }
  }

}
</script>
