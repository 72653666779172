import firebase from 'firebase/app'
import 'firebase/firestore'
require('firebase/auth')

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCVfTfq7jiB25w9CtXoUHpi5EnXYJBZPag",
    authDomain: "shareto-c15f9.firebaseapp.com",
    projectId: "shareto-c15f9",
    storageBucket: "shareto-c15f9.appspot.com",
    messagingSenderId: "645817222329",
    appId: "1:645817222329:web:0b4cc4f3945e311804c768"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore()

export default db;
