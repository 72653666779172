<template>
  <v-app  class="">
    
    <Navbar v-if="show"/>

    <v-main  class="mb-4" v-if="show">
      <router-view></router-view>
    </v-main>

    <v-main  class="ma-0" v-if="!show">
      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'


export default {
  name: 'App',
  components: { Navbar },

  data () {
    return {
      //
    }
  },
  computed: {
    show () {
      return this.$route.path != '/auth'; 
    }
  }
}
</script>
